import React from 'react';
import queryString from 'query-string';

import {ApolloClient, ApolloProvider, InMemoryCache} from '@apollo/client';
import Therapists from "./components/Therapists";

const client = new ApolloClient({
      uri: process.env.REACT_APP_API_SEVER,
      cache: new InMemoryCache(),
      headers: {
        Authorization: `Bearer ` + process.env.REACT_APP_API_SLT
      },
    }
);

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      displayList: 1139,
      showMap: true
    }

  }

  componentDidMount() {

    // eslint-disable-next-line no-restricted-globals
    const UrlQueryStrings = location.search;
    const queryValues = queryString.parse(UrlQueryStrings);

    this.setState({
      displayList: queryValues.displayList,
      showMap : queryValues.showMap === "true"
    })
  }

  render() {
    return (
        <ApolloProvider client={client}>
          <Therapists displayList={this.state.displayList} showMap={this.state.showMap}/>
        </ApolloProvider>

    )
  }
}

export default App;

