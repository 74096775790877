import React from 'react';

import {useQuery} from '@apollo/client';
import TherapistMap from "./TherapistMap";
import TherapistTable from "./TherapistTable";

import GET_THERAPISTS from "../queries/GetTherapists";

const Therapists = (props) => {

    const { loading, error, data } = useQuery(GET_THERAPISTS, {
        variables:
            {ID: props.displayList}
    });

    if (loading) return <p>Loading...</p>;
    if (error) return error.message;

    let map = null;
    if(props.showMap === true) {
        map = <TherapistMap data={data.entries}/>
    }

    return (
        <div>
            {map}
            <TherapistTable data={data}/>
        </div>
    )
}

export default Therapists;