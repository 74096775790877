import React from 'react';
import ReactDOM from 'react-dom';
import App from "./app";
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './index.css'

ReactDOM.render(<App />, document.querySelector('#root'));

/**/