import React from 'react';

class TherapistDetail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            therapist: props.therapist
        }
    }

    getMail = () => {

        if(this.state.therapist.email) {
            return (
                <span>
                    <i className="pi pi-envelope p-text-bold"/>&nbsp;<a href={"mailto:" + this.state.therapist.email}>{this.state.therapist.email}</a><br/>
                </span>
            )
        }
    }

    getTelephone = () => {

        if(this.state.therapist.telephone) {
            return (
                <span>
                    <i className="pi pi-bell p-text-bold"/>&nbsp;<a href={"tel:" + this.state.therapist.telephone}>{this.state.therapist.telephone}</a><br/>
                </span>
            )
        }

    }

   getWebsite = () => {

        if(this.state.therapist.website) {
            return (
                <span>
                    <i className="pi pi-home p-text-bold"/>&nbsp;<a href={this.state.therapist.website} target="_blank" rel="noreferrer">{this.state.therapist.website}</a><br/>
                </span>
            );
        }
    }

    getLanguageBlock = () => {

        if(this.state.therapist.languages.length > 0) {
            return (
                <div>
                    <h4>Talen</h4>
                    {this.state.therapist.languages.map((language, index) =>
                        <div key={index}>
                            {language.title}
                        </div>
                    )}
                </div>
            );
        }
    }

    getTargetGroupsBlock = () => {

        if(this.state.therapist.targetgroup.length > 0 ) {
            return(
                <div>
                    <h4>Doelgroepen</h4>
                    {this.state.therapist.targetgroup.map((group, index) =>
                        <div key={index}>
                            {group.title}
                        </div>
                    )}
                    <br/><hr className="divider"/>
                </div>
            );
        }
    }

    getHelpBlock = () => {

        if(this.state.therapist.helpOffered.length > 0) {
            return (
                <div>
                    <h4>Hulpvragen</h4>
                    {this.state.therapist.helpOffered.map((help, index) =>
                        <div key={index}>
                            {help.title}
                        </div>
                    )}
                    <br/><hr className="divider"/>
                </div>
            );
        }
    }

    render() {

        return (

            <React.Fragment>
                <p>
                    {this.state.therapist.address.name}<br/>
                    {this.state.therapist.address.street}<br/>
                    {this.state.therapist.address.postalCode}&nbsp;{this.state.therapist.address.city}
                </p>
                <p>
                    {this.getMail()}
                    {this.getTelephone()}
                    {this.getWebsite()}
                </p>
                <hr className="divider"/>
                {this.getTargetGroupsBlock()}
                {this.getHelpBlock()}
                {this.getLanguageBlock()}
            </React.Fragment>

        );
    }



}

export default TherapistDetail;