import {gql} from "@apollo/client";

const GET_THERAPISTS = gql`
    query getTherapists($ID: [QueryArgument]) {
        entries (section: "therapeut" relatedTo: $ID) {
            id
            title,
            ... on therapeut_therapeut_Entry {
                firstname,
                lastname,
                address {
                    name,
                    street,
                    street2,
                    postalCode,
                    city,
                    country,
                    latitude,
                    longitude
                },
                email,
                telephone,
                website,
                targetgroup {
                    title
                }
                preeducation {
                    title
                }
                helpOffered {
                    title
                }
                languages {
                    title
                }
            }
        }
        helprequests : categories(group: "helprequests") {
            title
        }
        targetgroups: categories(group: "targetgroups") {
            title
        }
        trainings: categories(group: "trainings") {
            title
        }
        languages: categories(group: "language") {
            title
        }
    }
`;

export default GET_THERAPISTS;