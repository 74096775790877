import React from 'react';

import { GMap } from 'primereact/gmap';
import TherapistDetail from "./TherapistDetail";
import {Dialog} from "primereact/dialog";

class TherapistMap extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            selectedTherapist: null,
            displayDetail: false,
            overlays: [],
            gmap: null,
            entries: props.data,
            options: {
                center: {lat: 51.001940, lng: 4.301460},
                zoom: 9
            }
        }
    }

    componentDidMount = () => {

        let array = [];

        // eslint-disable-next-line array-callback-return
        this.state.entries.map((entry) => {
            if (entry.address.latitude) {
                array.push(
                    // eslint-disable-next-line no-undef
                    new google.maps.Marker({
                        position: {
                            lat: entry.address.latitude,
                            lng: entry.address.longitude
                        },
                        title: entry.id,
                    })
                );
            }
        });

        this.setState({overlays: array});

        // eslint-disable-next-line no-undef
        new MarkerClusterer(this.gmap, array, {imagePath: process.env.PUBLIC_URL + `/m`});

    }

    onOverlayClick = (event) => {

        // eslint-disable-next-line array-callback-return
        this.state.entries.map((entry) => {
            if(entry.id === event.overlay.title) {
                this.setState({
                    selectedTherapist: entry,
                    displayDetail: true
                });
            }
        })
    }

    onMapReady = (event) => {

        console.log('called onMapReady ' + this.gmap);

    }

    onHide = (name) => {
        this.setState({
            [`${name}`]: false
        });
    }

    renderDetailHeader = () => {

        const therapist = this.state.selectedTherapist;

        if(therapist) {
            return (therapist.firstname + ' ' + therapist.lastname);
        }

    }

    renderDetail = () => {

        const therapist = this.state.selectedTherapist;

        if(therapist) {
            return <TherapistDetail therapist={therapist}/>;
        }
    }

    render() {

        const detailHeader = this.renderDetailHeader();

        return (
            <div>
                <Dialog header={detailHeader} visible={this.state.displayDetail} style={{ width: '50vw' }} onHide={() => this.onHide('displayDetail')}>
                    {this.renderDetail()}
                </Dialog>
                {/* eslint-disable-next-line no-undef */}
                <GMap options={this.state.options} overlays={this.state.overlays} style={{width: '100%', minHeight: '400px'}} onOverlayClick={this.onOverlayClick}
                      /* eslint-disable-next-line no-undef */
                      ref={(el) => {if(el){new MarkerClusterer(el.map, this.state.overlays, {imagePath: process.env.PUBLIC_URL + `/m`})}}}/>
            </div>

        );
    }

}

export default TherapistMap;