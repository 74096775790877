import React from 'react';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import { InputText } from 'primereact/inputtext';
import {MultiSelect} from "primereact/multiselect";

import './Therapists.css';
import {Dialog} from "primereact/dialog";
import TherapistDetail from "./TherapistDetail";

class TherapistTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            entries: props.data.entries,
            helprequests: props.data.helprequests.map((request) => ({label: request.title})),
            trainings: props.data.trainings.map((training) => ({label: training.title})),
            targetgroups: props.data.targetgroups.map((target) => ({label: target.title})),
            selectedStatus: null,
            selectedTraining: null,
            selectedTarget: null,
            selectedTherapist: null,
            displayDetail: false
        }
    }

    onHide = (name) => {
        this.setState({
            [`${name}`]: false
        });
    }

    onClick(name, position) {
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }

        this.setState(state);
    }


    addressBodyTemplate = (rowData) => {

        return (
            <React.Fragment>
                {rowData.address.street}&nbsp;{rowData.address.city}
            </React.Fragment>
        );
    }

    emailBodyTemplate = (rowData) => {

        return (
            <React.Fragment>
                <a href={"mailto:" + rowData.email}>{rowData.email}</a>
            </React.Fragment>
        )
    }

    helpRequestBodyTemplate = (rowData) => {

        return (
            rowData.helpOffered.map((offer, index) => (
                <React.Fragment key={index}>
                    {offer.title}<br/>
                </React.Fragment>
            ))
        )
    }

    targetRequestBodyTemplate = (rowData) => {

        return (
            rowData.targetgroup.map((target, index) => (
                <React.Fragment key={index}>
                    {target.title}<br/>
                </React.Fragment>
            ))
        )
    }

    trainingRequestBodyTemplate = (rowData) => {

        return(
            rowData.preeducation.map((training, index) => (
                <React.Fragment key={index}>
                    {training.title}<br/>
                </React.Fragment>
            ))
        )
    }

    renderHeader = () => {
        return (
            <div className="table-header">
                Therapeuten
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder="Zoek een therapeut" />
                </span>
            </div>
        );
    }

    renderStatusFilter = () => {
        return (
            <MultiSelect value={this.state.selectedStatus} options={this.state.helprequests} onChange={this.onStatusFilterChange}
                       placeholder="Hulpvraag" className="p-multiselect-filter-container" filter />
        );
    }

    renderTargetFilter = () => {
        return (
            <MultiSelect value={this.state.selectedTarget} options={this.state.targetgroups} onChange={this.onTargetFilterChange}
                         placeholder="Doelgroep" className="p-multiselect-filter-container" filter />
        );
    }

    renderTrainingFilter = () => {
        return (
            <MultiSelect value={this.state.selectedTraining} options={this.state.trainings} onChange={this.onTrainingFilterChange}
                         placeholder="Opleiding" className="p-multiselect-filter-container" filter />
        );
    }

    onStatusFilterChange = (event) => {

        this.dt.filter(event.value, 'helpOffered', 'custom');
        this.setState({selectedStatus: event.value});
    }

    onTargetFilterChange = (event) => {

        this.dt.filter(event.value, 'targetgroup', 'custom');
        this.setState({selectedTarget: event.value});
    }

    onTrainingFilterChange = (event) => {

        this.dt.filter(event.value, 'preeducation', 'custom');
        this.setState({selectedTraining: event.value});
    }

    filterHelpRequest = (value, filter) => {

        console.log(value);
        console.log(filter);

        let foundit = false;

        value.forEach((e) => {
            filter.forEach((f) => {
                if(e.title === f.label) foundit = true;
            })
        })

        return foundit;
    }


    renderDetailHeader = () => {

        const therapist = this.state.selectedTherapist;

        if(therapist) {
            return (therapist.firstname + ' ' + therapist.lastname);
        }

    }

    renderDetail = () => {

        const therapist = this.state.selectedTherapist;

        if(therapist) {
           return <TherapistDetail therapist={therapist}/>;
        }

    }

    render() {

        const header = this.renderHeader();
        const detailHeader = this.renderDetailHeader();
        const statusFilter = this.renderStatusFilter();
        const targetFilter = this.renderTargetFilter();

        //<Column field="telephone" header="Telefoon"/>
        //const trainingFilter = this.renderTrainingFilter();
        // <Column field="email" header="Email" body={this.emailBodyTemplate}/>
        //<Column field="preeducation" header="Opleiding" body={this.trainingRequestBodyTemplate} filter filterElement={trainingFilter} filterFunction={this.filterHelpRequest}/>

        return (
            <div className="card">
                <Dialog header={detailHeader} visible={this.state.displayDetail} style={{ width: '50vw' }} onHide={() => this.onHide('displayDetail')}>
                    {this.renderDetail()}
                </Dialog>
                <DataTable ref={(el) => this.dt = el} value={this.state.entries}
                           header={header} className="p-datatable-customers p-datatable-sm" dataKey="id" rowHover globalFilter={this.state.globalFilter}
                           selectionMode="single" selection={this.state.selectedTherapist} onSelectionChange={e => this.setState({selectedTherapist: e.value})}
                           onRowClick={() => this.onClick('displayDetail')}
                >
                    <Column field="firstname" header="Voornaam" filter filterPlaceholder="Voornaam"/>
                    <Column field="lastname" header="Achternaam" filter filterPlaceholder="Achternaam"/>
                    <Column field="address" header="Address" body={this.addressBodyTemplate} filterField="address.city" filter filterPlaceholder="Plaats" filterMatchMode="contains"/>
                    <Column field="targetgroup" header="Doelgroep" body={this.targetRequestBodyTemplate} filter filterElement={targetFilter} filterFunction={this.filterHelpRequest}/>
                    <Column field="helpOffered" header="Hulpvraag" body={this.helpRequestBodyTemplate} filter filterElement={statusFilter} filterFunction={this.filterHelpRequest}/>
                </DataTable>
            </div>
        )
    }
}

export default TherapistTable;